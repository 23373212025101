import React from 'react';
import { Helmet } from 'react-helmet';
import useSiteMetadata from '../../../hooks/useSiteMetadata';

type SEOLayoutProps = {
  pageTitle?: string | undefined | null;
  [otherProps: string]: any;
};

const SEOLayout = (props: SEOLayoutProps) => {
  const { children, pageTitle } = props;

  const { title, description } = useSiteMetadata();

  // TODO: I think we do not use fullstory anymore. 25/10/2024 Andriy
  // useFullStory();

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>{ pageTitle || title }</title>
        <meta name="description" content={description} />
        {/* Preload Tailwind CSS only for the home page to reduce FOUC (Flash of Unstyled Content) */}
        {pageTitle === "Huglo Comparison App - Compare your electricity and save" && (
          <link rel="preload" href="/tailwind.css" as="style" />
        )}
      </Helmet>
      {children}
    </>
  );
};

SEOLayout.defaultProps = {
  pageTitle: null
}

export default SEOLayout;
